import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

// @media(max - width: 1200px) {
//   grid - gap: 3rem;
// }
const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
`

/* const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
` */

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={4}>
      <Hero>
        <BigTitle>
          Hello, I am <br />
          Alexandre Robin
        </BigTitle>
        <Subtitle>I love to find the best solutions to problems and help others to do their best 🤔</Subtitle>
        <Subtitle>I am passionate about video games 🤓</Subtitle>
        <Subtitle>I guess that's why I am Associate Producer at Ubisoft Paris</Subtitle>
        {/* <Subtitle>
          Sometimes, I wish I would more write stuff on my <a href="https://blog.alexandrerobin.fr">BLOG</a>
        </Subtitle> */}
      </Hero>
      <Projects>
        <Title>Projects & Articles</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="How I built an HR Slack Bot with Node and Botkit"
            link="https://medium.freecodecamp.org/how-i-built-an-hr-slack-bot-with-node-and-botkit-6b23b81531bb"
            bg="https://cdn-images-1.medium.com/max/2000/1*3qx-9q-OBdf0zcge4Ca8yw.jpeg"
          >
            Article available on Medium - FreeCodeCamp
          </ProjectCard>
          <ProjectCard title="Jira Text Editor" link="https://jte.alexandrerobin.fr" bg="/media/jte.png">
            A WYSIWYG editor for Jira Rich Text Format
          </ProjectCard>
          <ProjectCard
            title="How I scrapped 7000 articles from LeMonde.Fr"
            link="https://medium.freecodecamp.org/how-i-scraped-7000-articles-from-a-newspaper-website-using-node-1309133a5070"
            bg="https://www.surlmag.fr/wp-content/uploads/2017/08/binet-interview-surl-cover-1-1900x839.jpg"
          >
            Article available on Medium - FreeCodeCamp
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <Contact>
        <Inner>
          <Title>Wanna chat ?</Title>
          <ContactText>
            Say <a href="mailto:alexandre44.robin@gmail.com">Hi</a> or find me on other platforms:{' '}
            <a href="https://www.instagram.com/alexandrobin_/">Instagram</a> || 
            <a href="https://www.linkedin.com/in/robinalexandre">LinkedIN</a> ||
            <a href="https://www.twitter.com/alexandrobin_">Twitter</a>
          </ContactText>
        </Inner>
        <Footer>
          &copy; 2018 by Gatsby Starter Portfolio Cara.{' '}
          <a href="https://github.com/LekoArts/gatsby-starter-portfolio-cara">Github Repository</a>. Made by{' '}
          <a href="https://www.lekoarts.de">LekoArts</a>.
        </Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
